const menuConfig = [
  {
    title: 'Home',
    path: '/'
  },
  {
    title: 'About us',
    path: '/about-us'
  },
  {
    title: 'Career Opportunities',
    path: '/careers'
  },
  {
    title: 'News & Events',
    path: '/news-events'
  },
  {
    title: 'Contact Us',
    path: '/contact-us'
  }
];

export default menuConfig;
